@charset "UTF-8";
.news .page-header {
  padding: 50px 15px 0;
  height: 144px;
  background-image: url(../images/common/ph_bg_sp.jpg);
  background-size: cover;
}
.news__header {
  padding: 36px 15px 20px;
}
.news__header .inner {
  margin: 0 auto;
  padding: 0 0 10px;
  max-width: 1100px;
  border-bottom: 1px solid #C0DBEC;
}
.news__date {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.news__date .date {
  margin-right: 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0;
}
.news__date .status {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  padding: 0 10px;
  height: 26px;
  font-size: 13px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 13px;
}
.news__category {
  margin-top: 14px;
}
.news__category ul {
  display: flex;
  flex-wrap: wrap;
  gap: 5px 10px;
}
.news__category span {
  display: inline-block;
  padding: 5px 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0;
  border: 1px solid #eee;
}
.news__category span.topics {
  color: #0072BC;
  border: 1px solid #0072BC;
}
.news__category span.maintenance {
  color: #D21C88;
  border: 1px solid #D21C88;
}
.news__category span.system {
  color: #0F7C84;
  border: 1px solid #0F7C84;
}
.news__category span.important {
  color: #E72929;
  border: 1px solid #E72929;
}
.news__title {
  margin-top: 15px;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.66;
}
.news__content .inner {
  margin: 0 auto;
  padding: 0 15px 50px;
  max-width: 1100px;
}
.news__content .inner .faq__item__cont {
  display: inline;
}
.news__content h3 {
  margin-top: 20px;
  padding-left: 10px;
  color: #0072BC;
  font-size: 17px;
  font-weight: 500;
  position: relative;
}
.news__content h3::before {
  content: "";
  display: block;
  width: 3px;
  height: 15px;
  border-left: 3px solid #0072BC;
  position: absolute;
  left: 0;
  top: 6px;
}
.news__content p {
  margin-top: 10px;
  font-size: 14px;
  line-height: 1.928;
}
.news__content ul {
  margin-top: 10px;
}
.news__gallery {
  margin: 0 auto 18px;
  max-width: 960px;
}
.news__gallery .flexBox {
  display: flex;
  flex-wrap: wrap;
}
.news__gallery .flexItem {
  margin: 18px 0;
  width: 100%;
}
.news__gallery figcaption {
  margin-top: 10px;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  line-height: 1.875;
  letter-spacing: 0;
}
.news__gotoArchive {
  margin-top: 40px;
}
.news__gotoArchive a {
  margin: 0 auto;
  padding: 0 0 0 20px;
  width: 200px;
  color: #fff;
}
.news__gotoArchive a::after {
  right: auto;
  left: 20px;
  transform: translateY(-50%) rotate(180deg);
}
.news__gotoArchive a:hover {
  background: #008EEB;
  border: 1px solid #008EEB;
}
.news__gotoArchive a:hover::after {
  left: 15px;
}

@media screen and (min-width: 768px) {
  .news .page-header {
    padding: 80px 15px 0;
    height: 222px;
    background-image: url(../images/common/ph_bg.jpg);
    background-size: cover;
  }
  .news__header {
    padding: 80px 15px 28px;
  }
  .news__header .inner {
    padding: 0 0 17px;
  }
  .news__date .date {
    margin-right: 20px;
    font-size: 18px;
  }
  .news__date .status {
    height: 28px;
    font-size: 14px;
    border-radius: 14px;
  }
  .news__category {
    margin-top: 15px;
  }
  .news__category ul {
    gap: 5px 16px;
  }
  .news__category span {
    padding: 7px 15px;
    font-size: 16px;
  }
  .news__title {
    margin-top: 15px;
    font-size: 30px;
    letter-spacing: 0;
  }
  .news__content {
    padding: 0 15px;
  }
  .news__content .inner {
    padding: 0 0 100px;
  }
  .news__content h3 {
    margin-top: 60px;
    padding-left: 22px;
    font-size: 20px;
  }
  .news__content h3::before {
    width: 7px;
    height: 24px;
    border-left: 7px solid #0072BC;
    top: 0;
  }
  .news__content p {
    margin-top: 15px;
    font-size: 16px;
    line-height: 2.25;
    letter-spacing: 0;
  }
  .news__content ul {
    margin-top: 15px;
  }
  .news__gallery {
    margin: 10px auto 28px;
  }
  .news__gallery .flexBox {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -30px;
  }
  .news__gallery .flexItem {
    margin: 40px 0;
    padding: 0 30px;
    width: 50%;
  }
  .news__gallery figcaption {
    margin-top: 25px;
    font-size: 18px;
  }
  .news__gotoArchive {
    margin-top: 70px;
  }
  .news__gotoArchive a {
    margin: 0 auto;
    padding: 0 0 0 30px;
    width: 300px;
  }
  .news__gotoArchive a::after {
    left: 30px;
  }
  .news__gotoArchive a:hover::after {
    left: 25px;
  }
}
/*
NEWS 一覧表示
*/
.news__archive {
  margin: 0;
  padding: 40px 15px 30px;
}
.news__archive .inner {
  margin: 0 auto;
  padding: 0;
  width: 100%;
  max-width: 1100px;
}
.news__archive__selector {
  height: 42px;
  position: relative;
}
.news__archive__selector__in {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  position: absolute;
  right: 0;
  top: 0;
}
.news__archive__selector .select__datelist {
  margin-left: 20px;
}
.news__archive__selector .select__wrap {
  display: block;
  padding: 0;
  background-color: #fff;
  border: 1px solid #CCCCCC;
  border-radius: 3px;
}
.news__archive__selector .select__btn {
  display: block;
  padding: 6px 41px 6px 14px;
  position: relative;
  cursor: pointer;
}
.news__archive__selector .select__btn::after {
  content: "";
  display: block;
  width: 12px;
  height: 8px;
  background-image: url(../images/common/icon_arw_gm.svg);
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: contain;
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translate(0, -50%) rotate(0);
  pointer-events: none;
}
.news__archive__selector .select__btn.on::after {
  transform: translate(0, -50%) rotate(180deg);
}
.news__archive__selector .select__list {
  display: none;
}
.news__archive__selector .select__list a {
  display: block;
  padding: 6px 14px;
  color: #333;
  font-size: 14px;
  text-decoration: none;
}
.news__archive__selector .select__list a:hover {
  color: #fff;
  background-color: #008EEB;
  opacity: 1;
}
.news__archive__selector .select__cate {
  width: 55%;
}
.news__archive__selector .select__datelist {
  width: 38%;
}
.news__archive__main {
  padding: 42px 0 0;
}
.news__archive__title {
  font-size: 18px;
  font-weight: 700;
}
.news__archive__list {
  margin-top: 2px;
}
.news__archive__item {
  padding: 16px 0 15px;
  border-bottom: 1px solid #C0DBEC;
}
.news__archive__item .meta {
  display: flex;
  align-items: center;
}
.news__archive__item .meta .date {
  font-size: 14px;
}
.news__archive__item .meta .tag {
  display: flex;
  align-items: center;
  margin-left: 10px;
  padding: 0 7px;
  height: 24px;
  font-size: 12px;
  line-height: 24px;
  border: 1px solid #ccc;
  border-radius: 12px;
}
.news__archive__item .categories {
  margin-top: 6px;
}
.news__archive__item .categories ul {
  display: flex;
  align-items: center;
  margin: 0 -5px;
}
.news__archive__item .categories li {
  padding: 0 5px;
}
.news__archive__item .categories li.important {
  color: #E72929;
  border: 1px solid #E72929;
  padding: 0 15px;
  height: 26px;
  font-size: 14px;
  line-height: 26px;
}
.news__archive__item .categories a {
  display: flex;
  align-items: center;
  padding: 0 7px;
  height: 20px;
  font-size: 12px;
  line-height: 20px;
  text-decoration: none;
  border: 1px solid #ccc;
}
.news__archive__item .categories a:hover {
  opacity: 1;
}
.news__archive__item .categories a.topics {
  color: #0072BC;
  border: 1px solid #0072BC;
}
.news__archive__item .categories a.topics:hover {
  color: #fff;
  background-color: #0072BC;
}
.news__archive__item .categories a.system {
  color: #0F7C84;
  border: 1px solid #0F7C84;
}
.news__archive__item .categories a.system:hover {
  color: #fff;
  background-color: #0F7C84;
}
.news__archive__item .categories a.maintenance {
  color: #D21C88;
  border: 1px solid #D21C88;
}
.news__archive__item .categories a.maintenance:hover {
  color: #fff;
  background-color: #D21C88;
}
.news__archive__item .textarea {
  margin-top: 3px;
}
.news__archive__item .textarea a {
  color: #333;
  font-size: 14px;
  line-height: 1.92;
  letter-spacing: 0;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

@media screen and (min-width: 768px) {
  .news__archive {
    padding: 80px 15px 60px;
  }
  .news__archive__selector {
    justify-content: flex-end;
  }
  .news__archive__selector__in {
    justify-content: flex-end;
  }
  .news__archive__selector .select__btn {
    padding: 2px 53px 2px 14px;
  }
  .news__archive__selector .select__btn::after {
    right: 20px;
  }
  .news__archive__selector .select__cate {
    width: auto;
    min-width: 223px;
  }
  .news__archive__selector .select__datelist {
    width: auto;
    min-width: 152px;
  }
  .news__archive__title {
    font-size: 24px;
  }
  .news__archive__list {
    margin-top: 30px;
  }
  .news__archive__item {
    padding: 20px;
  }
  .news__archive__item .head {
    display: flex;
    align-items: center;
  }
  .news__archive__item .meta .date {
    font-size: 16px;
  }
  .news__archive__item .meta .tag {
    padding: 0 10px;
    height: 28px;
    font-size: 14px;
    line-height: 28px;
    border-radius: 14px;
  }
  .news__archive__item .categories {
    margin-top: 0;
    margin-left: 20px;
  }
  .news__archive__item .categories ul {
    margin: 0 -7px;
  }
  .news__archive__item .categories li {
    padding: 0 7px;
  }
  .news__archive__item .categories li .important {
    padding: 0 15px;
    height: 26px;
    font-size: 14px;
    line-height: 26px;
  }
  .news__archive__item .categories a {
    padding: 0 15px;
    height: 26px;
    font-size: 14px;
    line-height: 26px;
  }
  .news__archive__item .textarea {
    margin-top: 5px;
  }
  .news__archive__item .textarea a {
    font-size: 16px;
  }
}
/*
PAGENAVI
*/
.pagenavi {
  padding: 30px 0 15px;
}
.pagenavi .inner {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  padding: 0;
  width: 100%;
  max-width: 1100px;
}
.pagenavi .pages {
  display: flex;
  align-items: center;
  justify-content: center;
}
.pagenavi a, .pagenavi span {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 2px;
  width: 35px;
  height: 35px;
  color: #333;
  font-size: 16px;
  text-decoration: none;
  letter-spacing: 0;
  border: 1px solid #C0DBEC;
  border-radius: 6px;
}
.pagenavi a:hover, .pagenavi .current {
  background-color: #EFEFEF;
  opacity: 1;
}
.pagenavi a.backword, .pagenavi a.prev, .pagenavi a.next, .pagenavi a.forword {
  position: relative;
}
.pagenavi a.backword::after, .pagenavi a.prev::after, .pagenavi a.next::after, .pagenavi a.forword::after {
  content: "";
  display: block;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: contain;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.pagenavi a.backword::after {
  width: 13px;
  height: 12px;
  background-image: url(../images/common/icon_backward_step.svg);
}
.pagenavi a.prev::after {
  width: 8px;
  height: 12px;
  background-image: url(../images/common/icon_prev.svg);
}
.pagenavi a.next::after {
  width: 8px;
  height: 12px;
  background-image: url(../images/common/icon_next.svg);
}
.pagenavi a.forword::after {
  width: 13px;
  height: 12px;
  background-image: url(../images/common/icon_forward_step.svg);
}

@media screen and (min-width: 768px) {
  .pagenavi {
    padding: 50px 0 40px;
  }
  .pagenavi a, .pagenavi span {
    margin: 0 4px;
    width: 45px;
    height: 45px;
    font-size: 18px;
  }
}