.news{

	.page-header{
		padding: 50px 15px 0;
		height: 144px;
		background-image: url(../images/common/ph_bg_sp.jpg);
		background-size: cover;
	}

	&__header{
		padding: 36px 15px 20px;

		.inner{
			margin: 0 auto;
			padding: 0 0 10px;
			max-width: 1100px;
			border-bottom: 1px solid #C0DBEC;
		}
	}

	&__date{
		display: flex;
		flex-wrap: wrap;
		align-items: center;

		.date{
			margin-right: 10px;
			font-size: 14px;
			font-weight: 400;
			line-height: 1;
			letter-spacing: 0;
		}

		.status{
			display: flex;
			align-items: center;
			justify-content: center;
			margin-right: 10px;
			padding: 0 10px;
			height: 26px;
			font-size: 13px;
			font-weight: 400;
			line-height: 1;
			letter-spacing: 0;
			background-color: #fff;
			border: 1px solid #ccc;
			border-radius: 13px;
		}
	}

	&__category{
		margin-top: 14px;

		ul{
			display: flex;
			flex-wrap: wrap;
			gap: 5px 10px;
		}

		span{
			display: inline-block;
			padding: 5px 12px;
			font-size: 14px;
			font-weight: 400;
			line-height: 1;
			letter-spacing: 0;
			border: 1px solid #eee;

			&.topics{
				color: #0072BC;
				border: 1px solid #0072BC;
			}

			&.maintenance{
				color: #D21C88;
				border: 1px solid #D21C88;
			}

			&.system{
				color: #0F7C84;
				border: 1px solid #0F7C84;
			}

			&.important{
				color: #E72929;
				border: 1px solid #E72929;
			}
		}
	}

	&__title{
		margin-top: 15px;
		font-size: 18px;
		font-weight: 400;
		line-height: 1.66;
	}

	&__content{

		.inner{
			margin: 0 auto;
			padding: 0 15px 50px;
			max-width: 1100px;

			.faq__item__cont {
				display: inline;
			}
		}

		h3{
			margin-top: 20px;
			padding-left: 10px;
			color: #0072BC;
			font-size: 17px;
			font-weight: 500;
			position: relative;

			&::before{
				content: "";
				display: block;
				width: 3px;
				height: 15px;
				border-left: 3px solid #0072BC;
				position: absolute;
				left: 0;
				top: 6px;
			}
		}

		p{
			margin-top: 10px;
			font-size: 14px;
			line-height: 1.928;
		}

		ul{
			margin-top: 10px;
		}
	}

	&__gallery{
		margin: 0 auto 18px;
		max-width: 960px;

		.flexBox{
			display: flex;
			flex-wrap: wrap;
		}

		.flexItem{
			margin: 18px 0;
			width: 100%;
		}

		figcaption{
			margin-top: 10px;
			font-size: 16px;
			font-weight: 400;
			text-align: center;
			line-height: 1.875;
			letter-spacing: 0;
		}
	}

	&__gotoArchive{
		margin-top: 40px;

		a{
			margin: 0 auto;
			padding: 0 0 0 20px;
			width: 200px;
			color: #fff;

			&::after{
				right: auto;
				left: 20px;
				transform: translateY(-50%) rotate(180deg);
			}

			&:hover{
		    background: #008EEB;
				border: 1px solid #008EEB;

				&::after{
					left: 15px;
				}
			}
		}
	}

}
@media screen and (min-width: 768px) {
	.news{

		.page-header{
			padding: 80px 15px 0;
			height: 222px;
			background-image: url(../images/common/ph_bg.jpg);
			background-size: cover;
		}

		&__header{
			padding: 80px 15px 28px;

			.inner{
				padding: 0 0 17px;
			}
		}
	
		&__date{
			.date{
				margin-right: 20px;
				font-size: 18px;
			}

			.status{
				height: 28px;
				font-size: 14px;
				border-radius: 14px;
			}
		}

		&__category{
			margin-top: 15px;

			ul{
				gap: 5px 16px;
			}

			span{
				padding: 7px 15px;
				font-size: 16px;
			}
		}

		&__title{
			margin-top: 15px;
			font-size: 30px;
			letter-spacing: 0;
		}

		&__content{
			padding: 0 15px;
	
			.inner{
				padding: 0 0 100px;
			}

			h3{
				margin-top: 60px;
				padding-left: 22px;
				font-size: 20px;

				&::before{
					width: 7px;
					height: 24px;
					border-left: 7px solid #0072BC;
					top: 0;
				}
			}

			p{
				margin-top: 15px;
				font-size: 16px;
				line-height: 2.25;
				letter-spacing: 0;
			}

			ul{
				margin-top: 15px;
			}
		}

		&__gallery{
			margin: 10px auto 28px;

			.flexBox{
				display: flex;
				flex-wrap: wrap;
				margin: 0 -30px;
			}

			.flexItem{
				margin: 40px 0;
				padding: 0 30px;
				width: 50%;
			}

			figcaption{
				margin-top: 25px;
				font-size: 18px;
			}
		}

		&__gotoArchive{
			margin-top: 70px;

			a{
				margin: 0 auto;
				padding: 0 0 0 30px;
				width: 300px;

				&::after{
					left: 30px;
				}

				&:hover{

					&::after{
						left: 25px;
					}
				}
			}
		}
	}
}

/*
NEWS 一覧表示
*/
.news__archive{
	margin: 0;
	padding: 40px 15px 30px;

	.inner{
		margin: 0 auto;
		padding: 0;
		width: 100%;
		max-width: 1100px;
	}

	&__selector{
		height: 42px;
    position: relative;

		&__in{
			display: flex;
			align-items: flex-start;
			justify-content: space-between;
			width: 100%;
			position: absolute;
			right: 0;
			top: 0;
		}

		.select__datelist{
			margin-left: 20px;
		}

		.select__wrap{
			display: block;
			padding: 0;
			background-color: #fff;
			border: 1px solid #CCCCCC;
			border-radius: 3px;
		}

		.select__btn{
			display: block;
			padding: 6px 41px 6px 14px;
			position: relative;
			cursor: pointer;

			&::after{
				content: "";
				display: block;
				width: 12px;
				height: 8px;
				background-image: url(../images/common/icon_arw_gm.svg);
				background-repeat: no-repeat;
				background-position: 0 0;
				background-size: contain;
				position: absolute;
				right: 15px;
				top: 50%;
				transform: translate(0, -50%) rotate(0);
				pointer-events: none;
			}

			&.on{
				&::after{
					transform: translate(0, -50%) rotate(180deg);
				}
			}
		}

		.select__list{
			display: none;

			a{
				display: block;
				padding: 6px 14px;
				color: #333;
				font-size: 14px;
				text-decoration: none;

				&:hover{
					color: #fff;
					background-color: #008EEB;
					opacity: 1;
				}
			}
		}

		.select__cate{
			width: 55%;
		}

		.select__datelist{
			width: 38%;
		}
	}

	&__main{
		padding: 42px 0 0;
	}

	&__title{
		font-size: 18px;
		font-weight: 700;
	}

	&__list{
		margin-top: 2px;
	}

	&__item{
		padding: 16px 0 15px;
		border-bottom: 1px solid #C0DBEC;

		.meta{
			display: flex;
			align-items: center;

			.date{
				font-size: 14px;
			}

			.tag{
				display: flex;
				align-items: center;
				margin-left: 10px;
				padding: 0 7px;
				height: 24px;
				font-size: 12px;
				line-height: 24px;
				border: 1px solid #ccc;
				border-radius: 12px;
			}
		}

		.categories{
			margin-top: 6px;

			ul{
				display: flex;
				align-items: center;
				margin: 0 -5px;
			}

			li{
				padding: 0 5px;
				&.important{
					color: #E72929;
					border: 1px solid #E72929;
					padding: 0 15px;
					height: 26px;
					font-size: 14px;
					line-height: 26px;
				}
			}

			a{
				display: flex;
				align-items: center;
				padding: 0 7px;
				height: 20px;
				font-size: 12px;
				line-height: 20px;
				text-decoration: none;
				border: 1px solid #ccc;

				&:hover{
					opacity: 1;
				}

				&.topics{
					color: #0072BC;
					border: 1px solid #0072BC;
					&:hover{
						color: #fff;
						background-color: #0072BC;
					}
				}

				&.system{
					color: #0F7C84;
					border: 1px solid #0F7C84;
					&:hover{
						color: #fff;
						background-color: #0F7C84;
					}
				}

				&.maintenance{
					color: #D21C88;
					border: 1px solid #D21C88;
					&:hover{
						color: #fff;
						background-color: #D21C88;
					}
				}
			}
		}

		.textarea{
			margin-top: 3px;

			a{
				color: #333;
				font-size: 14px;
				line-height: 1.92;
				letter-spacing: 0;

				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 1;
				overflow: hidden;
			}
		}
	}
}
@media screen and (min-width:768px){
	.news__archive{
		padding: 80px 15px 60px;

		&__selector{
			justify-content: flex-end;

			&__in{
				justify-content: flex-end;
			}

			.select__btn{
				padding: 2px 53px 2px 14px;

				&::after{
					right: 20px;
				}
			}

			.select__cate{
				width: auto;
				min-width: 223px;
			}

			.select__datelist{
				width: auto;
				min-width: 152px;
			}
		}

		&__title{
			font-size: 24px;
		}

		&__list{
			margin-top: 30px;
		}

		&__item{
			padding: 20px;

			.head{
				display: flex;
				align-items: center;
			}

			.meta{

				.date{
					font-size: 16px;
				}
	
				.tag{
					padding: 0 10px;
					height: 28px;
					font-size: 14px;
					line-height: 28px;
					border-radius: 14px;
				}
			}

			.categories{
				margin-top: 0;
				margin-left: 20px;

				ul{
					margin: 0 -7px;
				}

				li{
					padding: 0 7px;
					.important {
						padding: 0 15px;
						height: 26px;
						font-size: 14px;
						line-height: 26px;
					}
				}

				a{
					padding: 0 15px;
					height: 26px;
					font-size: 14px;
					line-height: 26px;
				}
			}

			.textarea{
				margin-top: 5px;

				a{
					font-size: 16px;
				}
			}
		}
	}
}




/*
PAGENAVI
*/
.pagenavi{
	padding: 30px 0 15px;

	.inner{
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0 auto;
		padding: 0;
		width: 100%;
		max-width: 1100px;
	}

	.pages{
		display: flex;
		align-items: center;
		justify-content: center;
	}

	a, span{
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0 2px;
		width: 35px;
		height: 35px;
		color: #333;
		font-size: 16px;
		text-decoration: none;
		letter-spacing: 0;
		border: 1px solid #C0DBEC;
		border-radius: 6px;
	}

	a:hover, .current{
		background-color: #EFEFEF;
		opacity: 1;
	}

	a.backword, a.prev, a.next, a.forword{
		position: relative;

		&::after{
			content: "";
			display:block;
			background-repeat: no-repeat;
			background-position: 50% 50%;
			background-size: contain;
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
		}
	}

	a.backword::after{
		width: 13px;
		height: 12px;
		background-image: url(../images/common/icon_backward_step.svg);
	}
	
	a.prev::after{
		width: 8px;
		height: 12px;
		background-image: url(../images/common/icon_prev.svg);
	}
	
	a.next::after{
		width: 8px;
		height: 12px;
		background-image: url(../images/common/icon_next.svg);
	}

	a.forword::after{
		width: 13px;
		height: 12px;
		background-image: url(../images/common/icon_forward_step.svg);
	}
}
@media screen and (min-width: 768px) {
	.pagenavi{
		padding: 50px 0 40px;

		a, span{
			margin: 0 4px;
			width: 45px;
			height: 45px;
			font-size: 18px;
		}
	}
}
